const canvasElem = $('#pointAnimate')[0];
const canvasStarImg = $('#canvasStar')[0];
let particleIndex = 0;
let particles = {};



function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

class Particle {
  constructor(canvas) {
    particleIndex++;
    particles[particleIndex] = this;

    this.x = canvas.width / 2;
    this.y = canvas.height / 2;

    this.vx = Math.random() * 6 - 3;
    this.vy = Math.random() * 4 - 2;
    this.growth = (Math.abs(this.vx) + Math.abs(this.vy)) * 0.004; // 根据x/y轴的位置决定大小
    this.id = particleIndex;
    this.size = 0;
    this.colorAlpha = 1;
    this.vAlpha = 0.1;
  }

  draw(ctx, canvas) {

    this.x += this.vx;
    this.y += this.vy;
    this.size += this.growth;

    if (this.x < 0 || this.x > canvas.width || this.y < 0 || this.y > canvas.height) {
        delete particles[this.id];
    }

    const alphaChange = {
      startTop: 20, // y轴坐标
      startBottom: (canvasElem.height - 120 - 20),
      startLeft: 20, // x轴坐标
      startRight: (canvasElem.width - 50)
    }

    if (this.x < alphaChange.startLeft || this.x > alphaChange.startRight ||
      this.y < alphaChange.startTop || this.y > alphaChange.startBottom) {
      if (this.colorAlpha > 0) {
        this.colorAlpha -= this.vAlpha;
      }
    }
    ctx.save();

    let g1 = ctx.createRadialGradient(this.x, this.y, this.size, this.x, this.y, 0);
    g1.addColorStop(0, 'rgba(16, 105, 215, 0)');
    let currentColor = 'rgba(16, 105, 215,' + this.colorAlpha + ')';
    g1.addColorStop(1, currentColor);
    ctx.fillStyle = g1;
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fill();

    ctx.restore();
  }
}

let pointAnimate = {

  init() {
    this.initCanvas();
    requestAnimationFrame(this.animate.bind(this));
  },

  initCanvas() {
    let winWidth = $(window).width();
    let canvasHeight = $('.banner').height();

    canvasElem.width = winWidth;
    canvasElem.height = canvasHeight;

    this.canvas = canvasElem;
    if (canvasElem.getContext) {
      this.ctx = canvasElem.getContext('2d');
    }
  },

  initParticles(count) {
    let particles = [];
    for (let i = 0; i < count; i++) {
      particles.push(new Particle(this.canvas));
    }
    return particles;
  },

  animate(){
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    if (Object.keys(particles).length < 1000 ) {
      new Particle(this.canvas);
    }

    for (var i in particles) {
        particles[i].draw(this.ctx, this.canvas);
    }
    requestAnimationFrame(this.animate.bind(this));
  }
};

export default pointAnimate;



