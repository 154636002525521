import pointAnimate from './pointAnimate.js'

const doms = {
	assetsChooseDom: $('#assetsChoose'),
	visualDom: $('#visual'),
	electricSignDom: $('#electricSign'),
	settlementDom: $('#settlement'),
	loanManageDom: $('#loanManage'),
	assetRecoveryDom: $('#assetRecovery')

}

let page = {
	init(){
		doms.assetsChooseDom.on('click',(event)=>this.gotoNext(event,"/assetsChoose.html"));
		doms.visualDom.on('click',(event)=>this.gotoNext(event,"/visualization.html"));
		doms.electricSignDom.on('click',(event)=>this.gotoNext(event,"/electricSign.html"));
		doms.settlementDom.on('click',(event)=>this.gotoNext(event,"/settlement.html"));
		doms.loanManageDom.on('click',(event)=>this.gotoNext(event,"/loanManage.html"));
		doms.assetRecoveryDom.on('click',(event)=>this.gotoNext(event,"/assetRecovery.html"));
    // alert(JSON.stringify(window.getComputedStyle($('.banner h1')[0], null)['text-align']));
	},

	gotoNext(event,url){
		window.location.href=url;
	}
}


page.init();
pointAnimate.init();
